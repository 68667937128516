import React from 'react';
import { useTheme } from '../theme/ThemeContext';
import { DarkMode, LightMode } from '@mui/icons-material';

const ThemeSwitcher = () => {
  const { mode, toggleTheme, theme } = useTheme();

  return (
    <button
      onClick={toggleTheme}
      className={`p-2 rounded-full transition-colors duration-200 ${
        mode === 'dark' 
          ? 'bg-gray-700 text-blue-300 hover:bg-gray-600' 
          : 'bg-gray-200 text-blue-600 hover:bg-gray-300'
      }`}
      aria-label={`Switch to ${mode === 'dark' ? 'light' : 'dark'} mode`}
    >
      {mode === 'dark' ? (
        <LightMode style={{ color: theme.primary }} />
      ) : (
        <DarkMode style={{ color: theme.primary }} />
      )}
    </button>
  );
};

export default ThemeSwitcher;