import React from 'react';
import { createRoot } from 'react-dom/client';
import './global.css';
import { ThemeProvider } from './theme/ThemeContext';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';

const root = createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <ThemeProvider>
      <Router>
        <App />
      </Router>
    </ThemeProvider>
  </React.StrictMode>
);