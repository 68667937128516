import React, { useState, useEffect, lazy, Suspense, useCallback } from 'react';
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import {
  Box,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Drawer,
  useMediaQuery,
  alpha,
  Menu,
  MenuItem,
} from '@mui/material';
import { useTheme as useMuiTheme } from '@mui/material/styles';
import {
  Menu as MenuIcon,
  MoreVert as MoreIcon,
} from '@mui/icons-material';
import { useTheme } from './theme/ThemeContext';
import SideNav from './components/SideNav';
import ThemeSwitcher from './components/ThemeSwitcher';
import BrainLoader from './components/BrainLoader';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL || 'http://localhost:3001',
});

// Lazy load components
const Contacts = lazy(() => import('./components/Contacts'));
const Metrics = lazy(() => import('./components/Metrics'));
const Appointments = lazy(() => import('./components/Appointments'));
const DataExplorer = lazy(() => import('./components/DataExplorer'));
const CallLogs = lazy(() => import('./components/CallLogs'));
const CallBatchInfo = lazy(() => import('./components/CallBatchInfo'));
const AIScripts = lazy(() => import('./components/AIScripts'));
const Schema = lazy(() => import('./components/Schema'));
const InstanceBuilder = lazy(() => import('./components/InstanceBuilder'));
const AskYourBrain = lazy(() => import('./components/AIChat'));

const drawerWidth = 240;

function App() {
  const [tables, setTables] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();
  const { mode } = useTheme();
  const muiTheme = useMuiTheme();
  const isMobile = useMediaQuery(muiTheme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(muiTheme.breakpoints.between('sm', 'md'));
  const [mobileOpen, setMobileOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const navItems = [
    { name: 'Dashboard', path: '/' },
    { name: 'Appointments', path: '/appointments' },
    { name: 'Calls', path: '/call-logs' },
    { name: 'Contacts', path: '/contacts' },
    { name: 'Scripts', path: '/scripts' },
    { name: 'Metrics', path: '/metrics' },
    { name: 'Explore Data', path: '/data-explorer' },
    { name: 'Instance Builder', path: '/build' },
    { name: 'Ask Your Brain', path: '/chat' },
  ];

  useEffect(() => {
    const fetchTables = async () => {
      try {
        const response = await api.get('/tables');
        setTables(response.data);
      } catch (error) {
        console.error('Error fetching tables:', error);
        toast.error('Failed to fetch tables');
      }
    };

    fetchTables();
  }, []);

  const showToast = useCallback((message, type = 'info') => {
    toast[type](message);
  }, []);

  const handleDrawerToggle = useCallback(() => {
    setMobileOpen((prevState) => !prevState);
  }, []);

  const handleMenuOpen = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleMenuClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleNavigation = useCallback((path) => {
    navigate(path);
    handleMenuClose();
    if (isMobile) {
      handleDrawerToggle();
    }
  }, [navigate, handleMenuClose, isMobile, handleDrawerToggle]);

  const pageVariants = {
    initial: { opacity: 0, y: 20 },
    in: { opacity: 1, y: 0 },
    out: { opacity: 0, y: -20 }
  };

  const pageTransition = {
    type: "tween",
    ease: "anticipate",
    duration: 0.3
  };

  const renderNavItems = (
    <>
      {navItems.slice(0, isTablet ? 2 : navItems.length).map((item) => (
        <IconButton
          key={item.name}
          color="inherit"
          onClick={() => handleNavigation(item.path)}
          sx={{
            fontWeight: location.pathname === item.path ? 600 : 400,
            fontSize: '0.875rem',
            textTransform: 'none',
            py: 0.5,
            px: 1,
            borderRadius: '4px',
            '&:hover': {
              backgroundColor: alpha(muiTheme.palette.primary.main, 0.08),
            },
            ...(location.pathname === item.path && {
              backgroundColor: alpha(muiTheme.palette.primary.main, 0.12),
              color: muiTheme.palette.primary.main,
            }),
          }}
        >
          {item.name}
        </IconButton>
      ))}
      {isTablet && (
        <IconButton color="inherit" onClick={handleMenuOpen}>
          <MoreIcon />
        </IconButton>
      )}
    </>
  );

  return (
    <Box sx={{ display: 'flex', minHeight: '100vh' }} className={`${mode} bg-background text-text-primary transition-all duration-300 ease-in-out`}>
      <AppBar
        position="fixed"
        elevation={0}
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          backgroundColor: alpha(muiTheme.palette.background.paper, mode === 'dark' ? 0.9 : 0.8),
          backdropFilter: 'blur(8px)',
          color: muiTheme.palette.text.primary,
          boxShadow: 'none',
          borderBottom: `1px solid ${alpha(muiTheme.palette.divider, 0.08)}`,
        }}
      >
        <Toolbar sx={{ minHeight: '48px', justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: 'none' } }}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
              BRAIN
            </Typography>
          </Box>
          {!isMobile && (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              {renderNavItems}
            </Box>
          )}
          <ThemeSwitcher />
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          <SideNav navItems={navItems} toggleSidebar={handleDrawerToggle} />
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
          open
        >
          <SideNav navItems={navItems} />
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          mt: { xs: '48px', sm: '64px' },
        }}
      >
        <AnimatePresence mode="wait">
          <Suspense fallback={<BrainLoader fullScreen={true} size="large" message="Loading..." />}>
            <motion.div
              key={location.pathname}
              initial="initial"
              animate="in"
              exit="out"
              variants={pageVariants}
              transition={pageTransition}
            >
              <Routes location={location}>
                <Route path="/" element={<Metrics tables={tables} showToast={showToast} />} />
                <Route path="/contacts" element={<Contacts showToast={showToast} />} />
                <Route path="/metrics" element={<Metrics showToast={showToast} />} />
                <Route path="/appointments" element={<Appointments showToast={showToast} />} />
                <Route path="/data-explorer" element={<DataExplorer showToast={showToast} />} />
                <Route path="/call-logs" element={<CallLogs showToast={showToast} />} />
                <Route path="/call-batch-info" element={<CallBatchInfo showToast={showToast} />} />
                <Route path="/scripts" element={<AIScripts showToast={showToast} />} />
                <Route path="/schema" element={<Schema showToast={showToast} />} />
                <Route path="/build" element={<InstanceBuilder showToast={showToast} />} />
                <Route path="/chat" element={<AskYourBrain showToast={showToast} />} />
                <Route path="*" element={
                  <div className="flex justify-center items-center h-full">
                    <h1 className="text-2xl font-bold text-primary">404: Page not found</h1>
                  </div>
                } />
              </Routes>
            </motion.div>
          </Suspense>
        </AnimatePresence>
      </Box>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        sx={{
          '& .MuiPaper-root': {
            backgroundColor: muiTheme.palette.background.paper,
            boxShadow: muiTheme.shadows[4],
          },
        }}
      >
        {navItems.slice(2).map((item) => (
          <MenuItem
            key={item.name}
            onClick={() => handleNavigation(item.path)}
            sx={{
              color: muiTheme.palette.text.primary,
              '&:hover': {
                backgroundColor: alpha(muiTheme.palette.primary.main, 0.08),
              },
            }}
          >
            {item.name}
          </MenuItem>
        ))}
      </Menu>
      <ToastContainer 
        position="bottom-right"
        theme={mode}
        toastClassName={() => 
          "relative flex p-4 min-h-10 rounded-md justify-between overflow-hidden cursor-pointer " + 
          (mode === 'dark' ? "bg-surface text-text-primary" : "bg-white text-gray-900")
        }
      />
    </Box>
  );
}

export default App;