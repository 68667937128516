import { createTheme, alpha } from '@mui/material/styles';

const createCustomShadows = (mode) => ({
  1: `0 1px 3px ${alpha(mode === 'light' ? '#000' : '#fff', 0.12)}`,
  2: `0 3px 6px ${alpha(mode === 'light' ? '#000' : '#fff', 0.14)}`,
  3: `0 10px 20px ${alpha(mode === 'light' ? '#000' : '#fff', 0.16)}`,
  4: `0 15px 25px ${alpha(mode === 'light' ? '#000' : '#fff', 0.18)}`,
  5: `0 20px 40px ${alpha(mode === 'light' ? '#000' : '#fff', 0.20)}`,
});

const createCustomPalette = (mode) => ({
  mode,
  primary: {
    main: mode === 'light' ? '#0056B3' : '#64B5F6',
    light: mode === 'light' ? '#64B5F6' : '#90CAF9',
    dark: mode === 'light' ? '#003C80' : '#42A5F5',
    contrastText: '#FFFFFF',
  },
  secondary: {
    main: mode === 'light' ? '#303F9F' : '#7986CB',
    light: mode === 'light' ? '#7986CB' : '#9FA8DA',
    dark: mode === 'light' ? '#1A237E' : '#5C6BC0',
    contrastText: '#FFFFFF',
  },
  background: {
    default: mode === 'light' ? '#F5F5F5' : '#121212',
    paper: mode === 'light' ? '#FFFFFF' : '#1E1E1E',
  },
  text: {
    primary: mode === 'light' ? '#212121' : '#FFFFFF',
    secondary: mode === 'light' ? '#757575' : '#B0B0B0',
  },
  success: {
    main: '#4CAF50',
    light: '#81C784',
    dark: '#388E3C',
  },
  warning: {
    main: '#FFC107',
    light: '#FFD54F',
    dark: '#FFA000',
  },
  error: {
    main: '#F44336',
    light: '#E57373',
    dark: '#D32F2F',
  },
});

const commonOverrides = (theme) => ({
  MuiCssBaseline: {
    styleOverrides: `
      * {
        transition: all 0.3s ease-in-out;
      }
      body {
        overflow-x: hidden;
        font-family: 'Inter var', sans-serif;
      }
    `,
  },
  MuiButton: {
    styleOverrides: {
      root: {
        borderRadius: 8,
        textTransform: 'none',
        padding: '10px 20px',
        transition: 'all 0.3s ease',
        boxShadow: theme.shadows[2],
        '&:hover': {
          transform: 'translateY(-2px)',
          boxShadow: theme.shadows[4],
        },
      },
      contained: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        '&:hover': {
          backgroundColor: theme.palette.primary.dark,
        },
      },
    },
  },
  MuiIconButton: {
    styleOverrides: {
      root: {
        transition: 'all 0.3s ease',
        '&:hover': {
          transform: 'scale(1.1)',
          backgroundColor: alpha(theme.palette.primary.main, 0.12),
        },
      },
    },
  },
  MuiPaper: {
    styleOverrides: {
      root: {
        borderRadius: 12,
        padding: '20px',
        transition: 'all 0.3s ease',
        boxShadow: theme.shadows[2],
        '&:hover': {
          boxShadow: theme.shadows[4],
        },
      },
    },
  },
  MuiAppBar: {
    styleOverrides: {
      root: {
        backgroundColor: alpha(theme.palette.background.default, 0.8),
        backdropFilter: 'blur(10px)',
        boxShadow: theme.shadows[3],
      },
    },
  },
  MuiDataGrid: {
    styleOverrides: {
      root: {
        border: 'none',
        '& .MuiDataGrid-cell': {
          borderBottom: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
        },
        '& .MuiDataGrid-columnHeaders': {
          backgroundColor: alpha(theme.palette.primary.main, 0.08),
          color: theme.palette.text.primary,
        },
        '& .MuiDataGrid-row:hover': {
          backgroundColor: alpha(theme.palette.action.hover, 0.04),
        },
      },
    },
  },
});

const createThemeConfig = (mode) => {
  const palette = createCustomPalette(mode);
  const shadows = createCustomShadows(mode);

  return createTheme({
    palette,
    shadows,
    typography: {
      fontFamily: 'Inter var, sans-serif',
    },
    shape: {
      borderRadius: 12,
    },
    components: {
      ...commonOverrides(createTheme({ palette, shadows })),
    },
  });
};

export const darkTheme = createThemeConfig('dark');
export const lightTheme = createThemeConfig('light');