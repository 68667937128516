import React from 'react';
import { List, ListItem, ListItemIcon, ListItemText, useMediaQuery } from '@mui/material';
import { useTheme as useMuiTheme } from '@mui/material/styles';
import { NavLink } from 'react-router-dom';
import { 
  Dashboard, Event, Call, People, Code, 
  Assessment, Storage, Build, Psychology 
} from '@mui/icons-material';
import { useTheme } from '../theme/ThemeContext';

const getIcon = (name) => {
  switch (name) {
    case 'Dashboard': return <Dashboard />;
    case 'Appointments': return <Event />;
    case 'Calls': return <Call />;
    case 'Contacts': return <People />;
    case 'Scripts': return <Code />;
    case 'Metrics': return <Assessment />;
    case 'Explore Data': return <Storage />;
    case 'Instance Builder': return <Build />;
    case 'Ask Your Brain': return <Psychology />;
    default: return <Dashboard />;
  }
};

const SideNav = ({ navItems = [], toggleSidebar }) => {
  const { mode } = useTheme();
  const muiTheme = useMuiTheme();
  const isMobile = useMediaQuery(muiTheme.breakpoints.down('sm'));

  if (!navItems || navItems.length === 0) {
    console.warn('No navItems provided to SideNav component');
    return null;
  }

  return (
    <List sx={{ pt: 2, px: isMobile ? 1 : 2 }}>
      {navItems.map((item) => (
        <ListItem
          key={item.name}
          component={NavLink}
          to={item.path}
          onClick={toggleSidebar}
          sx={{
            color: mode === 'dark' ? '#fff' : '#000',
            borderRadius: '8px',
            mb: 0.5,
            '&.active': {
              color: muiTheme.palette.primary.main,
              backgroundColor: mode === 'dark' 
                ? `rgba(${muiTheme.palette.primary.main}, 0.2)`
                : `rgba(${muiTheme.palette.primary.main}, 0.1)`,
            },
            '&:hover': {
              backgroundColor: mode === 'dark'
                ? `rgba(${muiTheme.palette.primary.main}, 0.1)`
                : `rgba(${muiTheme.palette.primary.main}, 0.05)`,
            },
          }}
        >
          <ListItemIcon sx={{ color: 'inherit', minWidth: isMobile ? 40 : 48 }}>
            {getIcon(item.name)}
          </ListItemIcon>
          <ListItemText 
            primary={item.name} 
            primaryTypographyProps={{ 
              fontSize: isMobile ? '0.8125rem' : '0.875rem',
              fontWeight: 500,
            }} 
          />
        </ListItem>
      ))}
    </List>
  );
};

export default SideNav;