import React from 'react';
import { Brain } from 'lucide-react';

const BrainLoader = ({ fullScreen = true, size = 'large'}) => {
  const sizeClasses = {
    small: 'w-12 h-12',
    medium: 'w-16 h-16',
    large: 'w-24 h-24',
  };

  const containerClasses = fullScreen
    ? 'fixed inset-0 z-50 flex flex-col justify-center items-center bg-background bg-opacity-75 backdrop-blur-sm'
    : 'flex flex-col justify-center items-center p-4';

  return (
    <div className={containerClasses}>
      <div className="relative flex justify-center items-center">
        <Brain className={`${sizeClasses[size]} text-primary animate-pulse`} />
      </div>
    
    </div>
  );
};

export default BrainLoader;